import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { UTCDateToNorwegian } from "../date/date";
import { AdventCalendar, Bibliobattles, Sommerles } from "@libry-content/types";

type Campaign = Pick<Sommerles | Bibliobattles | AdventCalendar, "promotion" | "startDate" | "endDate">;

export const isCampaignSeason = (
  { startDate, endDate, promotion }: Campaign,
  options?: {
    applyTeaserStartDate?: boolean;
  }
): boolean => {
  if (!startDate || !endDate) return false;

  const now = new Date();
  const thisYear = now.getFullYear();
  const start =
    (options?.applyTeaserStartDate && promotion?.startsAt && new Date(promotion?.startsAt)) ||
    UTCDateToNorwegian(new Date(`${thisYear}-${startDate}`));
  const end = UTCDateToNorwegian(new Date(`${thisYear}-${endDate}`));

  const daysUntilStart = differenceInCalendarDays(start, now);
  const daysSinceEnd = differenceInCalendarDays(now, end);

  return daysUntilStart <= 0 && daysSinceEnd < 1;
};
