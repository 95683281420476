import groq from "groq";
import { getPromotionFilter } from "../promotion";

const customCampaignIsStarted = groq`(startDate <= $today || ${getPromotionFilter()})`;

export const customCampaignIsNotFinished = groq`endDate >= $today`;

export const customCampaignIsOngoingGroqFilter = groq`($showCampaignOutsideSeason || ${customCampaignIsStarted} && ${customCampaignIsNotFinished})`;

const thisYear = new Date().getFullYear();

const campaignIsStarted = groq`(("${thisYear}-" + startDate <= $today) || ${getPromotionFilter()})`;

export const campaignIsNotFinished = groq`"${thisYear}-" + endDate >= $today`;

export const campaignIsInSeasonGroqFilter = groq`($showCampaignOutsideSeason || (enabled && ${campaignIsStarted} && ${campaignIsNotFinished}))`;
